import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "modern-normalize/modern-normalize.css"
import styled, { createGlobalStyle } from "styled-components"

import Header from "./header"

const Layout: React.FC<ILayout> = ({ children }): JSX.Element => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data): JSX.Element => (
        <>
          <GlobalStyles />
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:300,600&display=swap"
            rel="stylesheet"
          />
          <Header siteTitle={data.site.siteMetadata.title} />

          <Hero>
            <Copy>
              We are an indie Brazilian studio that makes software on demand.{" "}
              <strong>Games included.</strong>
            </Copy>

            <CTA>
              <Anchor
                href="https://store.steampowered.com/app/1060160/PureForge/"
                target="_blank"
                rel="noopener noreferrer"
              >
                See our next game on Steam
              </Anchor>{" "}
              or drop us a letter through{" "}
              <Anchor href="mailto:hello@vincae.com">hello@vincae.com</Anchor>
            </CTA>
          </Hero>
        </>
      )}
    />
  )
}

{
  /* <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 0,
            }}
          >
            <main>{children}</main>
            <footer>
              © {new Date().getFullYear()}, Built with
              {` `}
              <a href="https://www.gatsbyjs.org">Gatsby</a>
            </footer>
          </div> */
}

interface ILayout {
  children: React.ReactNode
}

const GlobalStyles = createGlobalStyle`
  body {
    font-family: "Open Sans";
    background-color: #111;
  }
`

const Hero = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`

const Copy = styled.div`
  padding: 0 40px;
  text-align: center;
  color: white;
  font-size: 24px;

  @media screen and (min-width: 640px) {
    font-size: 32px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 48px;
  }
`

const CTA = styled.div`
  margin-top: 40px;
  padding: 0 40px;
  color: white;
  font-size: 16px;
  text-align: center;
  color: #ccc;

  @media screen and (min-width: 640px) {
    font-size: 24px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 32px;
  }
`

const Anchor = styled.a`
  color: white;
  text-decoration: none;
  border-bottom: 1px solid #ffdc00;
  padding: 10px;
  line-height: 48px;
  transition: 0.1s ease-in-out all;

  @media screen and (min-width: 640px) {
    line-height: initial;
  }

  &:hover {
    color: #222;
    background: #ffdc00;
  }
`

export default Layout
