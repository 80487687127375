import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Wrapper } from "./grid"
import { Logo } from "./logo"

const Header: React.FC<IHeader> = ({ siteTitle = "" }): JSX.Element => (
  <Container>
    <Wrapper>
      <Presentation>
        <Link to="/">
          <Logo />
        </Link>
      </Presentation>
    </Wrapper>
  </Container>
)

interface IHeader {
  siteTitle: string
}

const Container = styled.header`
  position: absolute;
  margin-top: 100px;
  width: 100%;

  @media screen and (min-width: 640px) {
    margin-top: 200px;
  }
`

const Presentation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export default Header
