import { useEffect, useRef, MutableRefObject } from "react"

export function useInterval(callback: TCallback, delay: number): void {
  const savedCallback: MutableRefObject<TCallback | undefined> = useRef()

  useEffect((): void => {
    savedCallback.current = callback
  }, [callback])

  useEffect((): TCallback | undefined => {
    function tick(): void {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)

      return (): void => clearInterval(id)
    }
  }, [delay])
}

type TCallback = () => void
