import React from "react"

import { useInterval } from "../hooks/useInterval"

const colors: IColors[] = [
  {
    start: "#fff",
    stop: "#cdcdcd",
  },
  {
    start: "#FFD4E3",
    stop: "#FF0073",
  },
  {
    start: "#FFF9C8",
    stop: "#FFDC00",
  },
  {
    start: "#DBFFC8",
    stop: "#A1FF00",
  },
  {
    start: "#C8FFF1",
    stop: "#00FFCF",
  },
  {
    start: "#C8DCFF",
    stop: "#0084FF",
  },
  {
    start: "#E8C8FF",
    stop: "#A600FF",
  },
  {
    start: "#FFDBC8",
    stop: "#FF2E00",
  },
  {
    start: "#6D6D6D",
    stop: "#3E3E3E",
  },
]

export const Logo: React.FC = (): JSX.Element => {
  const [colorIndex, setColorIndex] = React.useState(0)
  const [isHovering, setIsHovering] = React.useState(false)

  useInterval((): void => {
    if (isHovering) {
      const isLastColor = colorIndex === colors.length - 1
      const newColorIndex = isLastColor ? 0 : colorIndex + 1

      setColorIndex(newColorIndex)
    }
  }, 100)

  function onMouseEnter(): void {
    setIsHovering(true)
  }

  function onMouseLeave(): void {
    setIsHovering(false)
    setColorIndex(0)
  }

  return (
    <svg
      width="175px"
      height="140px"
      viewBox="0 0 235 200"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <defs>
        <linearGradient
          x1="91.6694882%"
          y1="22.4135785%"
          x2="0.908786982%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stopColor={colors[colorIndex].start} offset="0%" />
          <stop stopColor={colors[colorIndex].stop} offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dark-Background-Copy-2"
          transform="translate(0.000000, -14.000000)"
          fill="url(#linearGradient-1)"
        >
          <g id="Group">
            <g id="Triangles">
              <polygon
                id="Triangle-Copy-2"
                transform="translate(86.490601, 123.793609) scale(1, -1) rotate(71.000000) translate(-86.490601, -123.793609) "
                points="-26.6342342 175.258233 150.528319 72.328986 199.615436 129.420622"
              />
              <polygon
                id="Triangle-Copy"
                transform="translate(176.500000, 114.000000) scale(-1, -1) rotate(270.000000) translate(-176.500000, -114.000000) "
                points="76.5 172.5 276.5 55.5 276.5 136.5"
              />
              <polygon
                id="Triangle-Copy-3"
                transform="translate(119.423473, 203.163038) scale(-1, 1) rotate(-10.000000) translate(-119.423473, -203.163038) "
                points="3.72099399 193.765709 183.296089 171.612033 235.125952 234.714044"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

interface IColors {
  start: string
  stop: string
}
